<template>
  <div>
    <!-- Back Button -->
    <v-row style="margin-bottom: 20px">
      <router-link style="margin-right: 20px" :to="{ name: 'StatisticsCrawlersToday' }">zurück</router-link>
      <v-icon size="40px">$pacman</v-icon>
      <div style="font-size: 30px; margin-left: 20px">Crawlers</div>
    </v-row>

    <h2>
      Crawler - {{data.singleId}}
    </h2>
    <div style="max-width: 50%">
      <v-container>
        <v-row>
          <v-col>
            <b>
              ID
            </b>
          </v-col>
          <v-col>
            {{data.singleId}}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>
              Zeitung
            </b>
          </v-col>
          <v-col>
            {{data.paper}}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>
              Region
            </b>
          </v-col>
          <v-col>
            {{data.baseURL}}
          </v-col>
        </v-row>
      </v-container>
    </div>

    <crawler-info :data="data.today[0]" heading="Heute"></crawler-info>
    <crawler-info :data="data.past7Days[0]" heading="Letzte 7 Tage"></crawler-info>
    <crawler-info :data="data.past14Days[0]" heading="Letzte 14 Tage"></crawler-info>
  </div>
</template>

<script>
import CrawlerInfo from '@/components/statistics/CrawlerInfo.vue'
import axios from 'axios'

export default {
  data () {
    return {
      data: {}
    }
  },
  created () {
    axios.get('/statistics/crawler/crawlerDetailsById', { params: { id: this.id } })
      .then(res => {
        this.data = res.data
      })
      .catch(() => {})
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  components: {
    CrawlerInfo
  }
}
</script>
