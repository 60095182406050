<template>
  <div style="margin: 40px 0">
    <h2>
      {{ heading }}
    </h2>
    <div>
      <v-container>
        <v-row>
          <!-- Info -->
          <v-col cols="6">
            <v-row>
              <v-col>
                <b>
                  Runs
                </b>
              </v-col>
              <v-col>
                {{data.runs}}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <b>
                  Gefunden
                </b>
              </v-col>
              <v-col>
                {{data.casesIn}}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <b>
                  Ausgeschlossen
                </b>
              </v-col>
              <v-col>
                {{data.preFiltered}}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <b>
                  Duplikate
                </b>
              </v-col>
              <v-col>
                {{data.duplicatesPrefiltered}}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <b>
                  Veröffentlicht
                </b>
              </v-col>
              <v-col>
                {{data.casesPushed}}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <b>
                  Ausstehend
                </b>
              </v-col>
              <v-col>
                {{data.inQueue}}
              </v-col>
            </v-row>
          </v-col>
          <!-- Histogram -->
          <v-col cols="6">
            <apex-charts
              type="bar" height="100%" :options="options" :series="series"
            ></apex-charts>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
export default {
  props: ['data', 'heading'],
  computed: {
    options () {
      var arr = Object.keys(this.data.idHistogram)
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === 'false') {
          arr[i] = 'ohne'
        }
      }
      return {
        xaxis: {
          categories: arr
        }
      }
    },
    series () {
      var arr = []
      for (var key in this.data.idHistogram) {
        arr.push(this.data.idHistogram[key])
      }
      return [{
        data: arr
      }]
    }
  },
  components: {
    ApexCharts
  }
}
</script>
